<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <h3>Updates</h3>
      </v-col>
      <v-col cols="2">
        Delete
        <v-icon
          :disabled="update.active"
          @click.passive="deleteMessage"
          color="red"
          >delete</v-icon
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          label="Label"
          v-model="update.title"
          autofocus
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <template v-if="isEditable">
          <v-textarea
            rows="4"
            counter="1250"
            maxlength="1250"
            label="Description"
            id="description"
            v-model="update.description"
            @blur="blurProfileDescription"
          ></v-textarea>
        </template>

        <template v-else>
          <h4>Descroption</h4>
          <div
            class="fs-14 border overflow-y-auto word-breaking"
            v-html="compiledMarkdown"
            @click="isEditable = true"
            style="height:160px"
          ></div>
        </template>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="8" sm="6" lg="4">
        <v-row align="center">
          <v-col cols="6" sm="4">
            <v-switch
              v-model="update.target_user"
              label="Producers"
              value="producers"
            ></v-switch>
          </v-col>
          <v-col cols="6" sm="4">
            <v-switch
              v-model="update.target_user"
              label="Consumers"
              value="consumers"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-switch
          v-model="update.active"
          color="accent"
          label="Active?"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn color="success" @click.stop="save">Save</v-btn>
        <v-btn to="/admin/update/list" text>cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import marked from 'marked'
import moment from 'moment'

export default {
  data() {
    return {
      isEditable: true,
      update: {
        target_user: []
      }
    }
  },
  computed: {
    compiledMarkdown() {
      return this.update.description
        ? marked(this.update.description, { sanitize: true })
        : ''
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store
        .dispatch('getUpdate', { id: this.$route.params.id })
        .then(d => {
          this.update = d
          this.update.id = this.$route.params.id
          this.isEditable = false
        })
    } else {
      this.update.createdTime = moment.utc().valueOf()
    }
  },
  methods: {
    blurProfileDescription() {
      this.isEditable = false
    },
    deleteMessage() {
      this.$store.dispatch('deleteUpdate', this.$route.params.id)
      this.$router.push('/admin/update/list')
    },
    save() {
      //  createdTime: now.valueOf()
      this.update.updatedTime = moment.utc().valueOf()
      this.$store.dispatch('saveUpdate', this.update)
      this.$store.dispatch('setMessage', {
        title: 'Save',
        body: 'System update saved.'
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
